import React, { useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useRouteMatch } from 'react-router';
import { PaginationEnum } from '../../customTypes';
import { useRouter, useStoreState, usePagination, useMedia } from '../../hooks';
import { constants } from '../../utility';
import { DataLoading, Search } from '../common';
import { CustomPagination } from '../common/CustomPagination';
import './index.scss';
import SearchItem from './SearchItem';

const SearchMedia: React.FC = () => {
  const { getParams, navigateTo } = useRouter();
  const {
    search: {
      totalResult,
      isSearching,
      searchedMedia,
      hasSearchFailed,
      searchQuery,
      currentPageNumber,
    },
  } = useStoreState();

  const mediaFromParam = useMemo(() => {
    return getParams().media;

    //eslint-disable-next-line
  }, [getParams().media]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalResult / constants.searchLimitPerPage);
    //eslint-disable-next-line
  }, [totalResult]);

  const isBookSearch = useRouteMatch('/search/books');
  const isAudioSearch = useRouteMatch('/search/audios');
  const isVideoSearch = useRouteMatch('/search/videos');

  const { pageChange } = usePagination(PaginationEnum.Search);
  const { searchMedia, selectMedia } = useMedia();

  useEffect(() => {
    if (!isBookSearch && !isAudioSearch && !isVideoSearch) {
      navigateTo('/');
    }
    //eslint-disable-next-line
  }, [isBookSearch, isAudioSearch, isVideoSearch]);

  useEffect(() => {
    if (hasSearchFailed) {
      navigateTo(`/${mediaFromParam}`);
      return;
    }

    if (!isSearching && !searchedMedia) {
      navigateTo(`/${mediaFromParam}`);
      return;
    }

    //eslint-disable-next-line
  }, [isSearching, searchedMedia, hasSearchFailed]);

  return (
    <Container className="searchMedia">
      <Row>
        <Col>
          <Search
            media={
              mediaFromParam === 'books'
                ? 'Book'
                : mediaFromParam === 'audios'
                ? 'Audio'
                : 'Video'
            }
            placeHolderText="Search ..."
            onSearch={searchMedia}
          />
          {isSearching && <DataLoading />}
          {!isSearching && searchedMedia && (
            <div className="searchMedia__searchResultInfo">
              <h5>Search Results: '{searchQuery}'</h5>
              <p>
                {totalResult} {mediaFromParam} found
              </p>
            </div>
          )}
        </Col>
      </Row>
      <div className="searchMedia__itemContainer">
        {searchedMedia &&
          searchedMedia.map((item) => {
            return (
              <div className="searchMedia__item" key={item.id}>
                <SearchItem
                  onItemSelect={selectMedia}
                  searchItem={item}
                  width={
                    mediaFromParam === 'books' || mediaFromParam === 'audios'
                      ? 150
                      : 280
                  }
                  height={
                    mediaFromParam === 'books'
                      ? 225
                      : mediaFromParam === 'audios'
                      ? 150
                      : 175
                  }
                />
              </div>
            );
          })}
      </div>

      {!isSearching && searchedMedia && searchedMedia.length > 0 && (
        <div className="searchMedia__pagination">
          <CustomPagination
            currentPage={currentPageNumber}
            totalPages={totalPages}
            onPageClick={(page) => {
              pageChange(page, searchQuery, mediaFromParam);
            }}
          />
        </div>
      )}
    </Container>
  );
};

export default SearchMedia;
