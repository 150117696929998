import { ICartItem } from '../models';
import { useStoreActions } from './useStoreActions';
import { useStoreState } from './useStoreState';

export const useCart = () => {
  const {
    deleteItemFromCart,
    updateCartItemQuantity,
    deleteAllItemsFromCart,
    resetAddToCartSuccess,
  } = useStoreActions();
  const {
    auth: { token, userVat },
  } = useStoreState();

  const removeItem = (id: number) => {
    deleteItemFromCart(id, token);
  };

  const changeItemQuantity = (item: ICartItem, shouldIncrease?: boolean) => {
    if (item.isEContent) {
      return;
    }

    if (item.totalQuantity <= 1 && !shouldIncrease) {
      return;
    }

    updateCartItemQuantity(item, token, userVat, shouldIncrease);
  };

  const clearCart = () => {
    deleteAllItemsFromCart(token);
  };

  const resetCartSuccess = () => {
    resetAddToCartSuccess();
  };

  return { removeItem, changeItemQuantity, clearCart, resetCartSuccess };
};
