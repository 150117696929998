import React, { useCallback, useMemo, useState } from 'react';
import { useMedia, useStoreState } from '../../hooks';
import { media } from '../../utility';
import { Banner, VideoRibbon, GenreList, Search } from '../common';
import { FeatureRibbon } from '../common/FeatureRibbon';
import MediaAlert from '../common/MediaAlert';
import MediaSnackBar from '../common/MediaSnackBar';
import './index.scss';

const Videos: React.FC = () => {
  const {
    media: { ribbonVideos, featuredVideos },
    auth: { userCurrency },
  } = useStoreState();
  const [selectedGenre, setSelectedGenre] = useState('All');
  const { selectMedia, searchMedia, addMediaToCart, addMediaToLibrary } =
    useMedia();

  const uniqueContentType = useMemo(() => {
    if (ribbonVideos) {
      return media.getUniqueMediaContentType(ribbonVideos);
    } else {
      return [];
    }
  }, [ribbonVideos]);

  const uniqueGenres = useMemo(() => {
    if (ribbonVideos) {
      return ['All', ...media.getUniqueMediaGenres(ribbonVideos)];
    } else {
      return [];
    }
  }, [ribbonVideos]);

  const displayVideos = useMemo(() => {
    if (ribbonVideos) {
      return media.getVideoByGenre(ribbonVideos, selectedGenre);
    } else {
      return [];
    }
  }, [ribbonVideos, selectedGenre]);

  const getVideoByContentType = useCallback(
    (contentType: string) => {
      const filteredVideos = displayVideos.filter(
        (video) => video.contentType === contentType
      );

      return filteredVideos;
    },

    //eslint-disable-next-line
    [ribbonVideos, selectedGenre, displayVideos]
  );

  const handleGenreSelect = (genre: string) => {
    setSelectedGenre(genre);
  };

  return (
    <div className="videos">
      <div className="videos__banner">
        <Banner
          title="Cholishnu Video"
          subtitle="We are producing high-quality videos on a wide range of topics covering arts, culture, literature, science and travel."
        />
      </div>
      <div className="videos__search">
        <Search
          onSearch={searchMedia}
          media="Video"
          placeHolderText="🔎  Search Video ..."
        />
      </div>
      {featuredVideos && featuredVideos.length > 0 && (
        <div className="videos__ribbonHeader">
          <h3>Featured</h3>
          <hr className="videos__divider" />
        </div>
      )}

      {featuredVideos && featuredVideos.length > 0 && (
        <div style={{ marginBottom: 30 }} className="videos__ribbon">
          <FeatureRibbon
            onAddToLibrary={addMediaToLibrary}
            onAddToCart={addMediaToCart}
            onFeatureSelect={selectMedia}
            features={featuredVideos}
            currency={userCurrency}
          />
        </div>
      )}
      <div className="videos__genre">
        <GenreList
          selectedGenre={selectedGenre}
          genres={uniqueGenres}
          onGenreSelect={handleGenreSelect}
        />
      </div>

      {ribbonVideos &&
        ribbonVideos.length > 0 &&
        displayVideos.length > 0 &&
        uniqueContentType.map((contentType) => {
          return (
            <div key={contentType}>
              {getVideoByContentType(contentType).length > 0 && (
                <div className="videos__ribbonHeader">
                  <h3>{contentType}</h3>
                  <hr className="videos__divider" />
                </div>
              )}

              {ribbonVideos &&
                ribbonVideos.length > 0 &&
                displayVideos.length > 0 &&
                getVideoByContentType(contentType).length > 0 && (
                  <div className="videos__ribbon">
                    <VideoRibbon
                      onDeleteFromLibrary={() => {}}
                      onAddToLibrary={addMediaToLibrary}
                      onAddToCart={addMediaToCart}
                      onVideoSelect={selectMedia}
                      video={getVideoByContentType(contentType)}
                      currency={userCurrency}
                    />
                  </div>
                )}
            </div>
          );
        })}
      <MediaAlert />
      <MediaSnackBar />
    </div>
  );
};

export default Videos;
