import React, { useMemo } from 'react';
import { ICartItem } from '../../models';
import { Button, Col, Container, Row } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteIcon from '@material-ui/icons/Delete';
import './individualItem.scss';
import { price } from '../../utility';

interface IndividualItemProps {
  item: ICartItem;
  onRemoveItem: (id: number) => void;
  onUpdateQuantity: (item: ICartItem, shouldIncrease?: boolean) => void;
}

const IndividualItem: React.FC<IndividualItemProps> = ({
  item,
  onRemoveItem,
  onUpdateQuantity,
}) => {
  const itemCoverStyle = useMemo(() => {
    if (item.itemMedia === 'Book') {
      return {
        width: 120,
        height: 180,
      };
    } else if (item.itemMedia === 'Audio') {
      return {
        width: 120,
        height: 120,
      };
    } else {
      return {
        width: 150,
        height: 100,
      };
    }
  }, [item]);

  const hasDiscount = useMemo(() => {
    return item.discount > 0;
  }, [item]);

  const priceAfterDiscount = useMemo(() => {
    return item.totalPrice - item.discount;
    //eslint-disable-next-line
  }, [item, item.discount, item.totalPrice]);

  return (
    <Container className="individualItem">
      <Row className="individualItem__itemRow">
        <Col md={3} className="individualItem__coverArtCol">
          <img
            alt={`${item.itemTitle} cover`}
            style={itemCoverStyle}
            src={item.itemCoverImage}
          />
        </Col>
        <Col md={6}>
          <h5 className="individualItem__title">{item.itemTitle}</h5>
          <div className="text-muted">{item.itemContentType}</div>
          {!hasDiscount && (
            <div className="individualItem__price">
              {price.format(item.currency, item.totalPrice)}
            </div>
          )}
          {hasDiscount && (
            <div className="individualItem__price">
              {price.format(item.currency, priceAfterDiscount)} (
              <span className="individualItem__discount">
                {price.format(item.currency, item.totalPrice)}
              </span>
              )
            </div>
          )}

          <div className="individualItem__remove">
            <Button
              onClick={() => onRemoveItem(item.basketId)}
              variant="outline-danger"
            >
              <DeleteIcon /> Remove
            </Button>
          </div>
        </Col>
        <Col className="individualItem__quantityCol" md={2}>
          <div className="individualItem__quantity">
            <Button
              onClick={() => onUpdateQuantity(item, true)}
              variant="outline-dark"
            >
              <AddIcon className="individualItem__icon" />
            </Button>
            <div className="individualItem__quantityValue">
              {item.totalQuantity}
            </div>

            <Button
              onClick={() => onUpdateQuantity(item, false)}
              variant="outline-dark"
            >
              <RemoveIcon className="individualItem__icon" />
            </Button>
          </div>
        </Col>
      </Row>
      <hr />
    </Container>
  );
};

export default IndividualItem;
