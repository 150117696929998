import { ICheckoutAction } from '../actions/interface';
import { ActionTypes } from '../types';

interface ICheckoutReducer {
  totalPrice: number | null;
  totalVat: number | null;
  totalDiscount: number | null;
  sslGatewayUrl: string | null;
  isSSLPurchaseInProgress: boolean;
  isSSLPurchaseSuccess: boolean;
  isSSLPurchaseFailed: boolean;
  stripeSessionId: string | null;
  isStripePurchaseInProgress: boolean;
  isStripePurchaseSuccess: boolean;
  isStripePurchaseFailed: boolean;
}

const initialState = {
  totalPrice: null,
  totalVat: null,
  totalDiscount: null,
  sslGatewayUrl: null,
  isSSLPurchaseInProgress: false,
  isSSLPurchaseSuccess: false,
  isSSLPurchaseFailed: false,
  stripeSessionId: null,
  isStripePurchaseInProgress: false,
  isStripePurchaseSuccess: false,
  isStripePurchaseFailed: false,
};

//eslint-disable-next-line
export default (
  state: ICheckoutReducer = initialState,
  action: ICheckoutAction
): ICheckoutReducer => {
  switch (action.type) {
    case ActionTypes.SET_CHECKOUT_PRICE:
      return {
        ...state,
        totalPrice: action.payload.totalPrice,
        totalDiscount: action.payload.totalDiscount,
        totalVat: action.payload.totalVat,
      };
    case ActionTypes.SET_SSL_GATEWAY:
      return {
        ...state,
        sslGatewayUrl: action.payload,
      };
    case ActionTypes.PURCHASE_SSL_IN_PROGRESS:
      return {
        ...state,
        sslGatewayUrl: null,
        isSSLPurchaseInProgress: true,
        isSSLPurchaseSuccess: false,
        isSSLPurchaseFailed: false,
      };
    case ActionTypes.PURCHASE_SSL_SUCCESS:
      return {
        ...state,
        sslGatewayUrl: action.payload,
        isSSLPurchaseInProgress: false,
        isSSLPurchaseSuccess: true,
        isSSLPurchaseFailed: false,
      };
    case ActionTypes.PURCHASE_SSL_FAILED:
      return {
        ...state,
        sslGatewayUrl: null,
        isSSLPurchaseInProgress: false,
        isSSLPurchaseSuccess: false,
        isSSLPurchaseFailed: true,
      };
    case ActionTypes.PURCHASE_STRIPE_IN_PROGRESS:
      return {
        ...state,
        stripeSessionId: null,
        isStripePurchaseInProgress: true,
        isStripePurchaseSuccess: false,
        isStripePurchaseFailed: false,
      };
    case ActionTypes.PURCHASE_STRIPE_SUCCESS:
      return {
        ...state,
        stripeSessionId: action.payload,
        isStripePurchaseInProgress: false,
        isStripePurchaseSuccess: true,
        isStripePurchaseFailed: false,
      };
    case ActionTypes.PURCHASE_STRIPE_FAILED:
      return {
        ...state,
        stripeSessionId: null,
        isStripePurchaseInProgress: false,
        isStripePurchaseSuccess: false,
        isStripePurchaseFailed: true,
      };

    default:
      return state;
  }
};
