import { useHistory, useParams } from 'react-router';

export const useRouter = () => {
  const history = useHistory();
  const params: any = useParams();

  const navigateTo = (path: string) => {
    history.push(path);
  };

  const getParams = () => {
    return params;
  };

  return { navigateTo, getParams };
};
