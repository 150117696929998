import React, { useEffect } from 'react';
import { useLoadMedia, useMedia, useRouter, useStoreState } from '../../hooks';
import { BookRibbon, DataLoading } from '../common';
import MediaAlert from '../common/MediaAlert';
import MediaSnackBar from '../common/MediaSnackBar';
import BookDetail from './BookDetail';
import './index.scss';

const BookById: React.FC = () => {
  const { getParams, navigateTo } = useRouter();
  const { loadBookById, loadBookBySameAuthor, loadBookBySamePublisher } =
    useLoadMedia();

  const {
    auth: { userCurrency },
    media: {
      currentBook,
      currentBookSameAuthor,
      currentBookSamePublisher,
      isCurrentBookLoading,
      hasCurrentBookErrorOccured,
    },
  } = useStoreState();
  const { selectMedia, addMediaToCart, addMediaToLibrary } = useMedia();

  useEffect(() => {
    const id = getParams().id;
    loadBookById(+id);
    loadBookBySameAuthor(+id);
    loadBookBySamePublisher(+id);

    //eslint-disable-next-line
  }, [getParams().id]);

  useEffect(() => {
    if (hasCurrentBookErrorOccured) {
      navigateTo('/');
    }
    //eslint-disable-next-line
  }, [hasCurrentBookErrorOccured]);

  if (isCurrentBookLoading || !currentBook) {
    return <DataLoading />;
  } else {
    return (
      <div className="bookById">
        <BookDetail
          onAddToLibrary={addMediaToLibrary}
          onAddToCart={addMediaToCart}
          book={currentBook}
          userCurrency={userCurrency}
        />
        {currentBookSamePublisher && currentBookSamePublisher.length > 0 && (
          <div className="bookById__ribbonHeader">
            <h3>Other Books by {currentBook.publisher}</h3>
            <hr className="bookById__divider" />
          </div>
        )}

        {currentBookSamePublisher && currentBookSamePublisher.length > 0 && (
          <div className="bookById__ribbon">
            <BookRibbon
              onDeleteFromLibrary={() => {}}
              onAddToLibrary={addMediaToLibrary}
              onAddToCart={addMediaToCart}
              onBookSelect={selectMedia}
              books={currentBookSamePublisher}
              currency={userCurrency}
            />
          </div>
        )}
        {currentBookSameAuthor && currentBookSameAuthor.length > 0 && (
          <div className="bookById__ribbonHeader">
            <h3>Books by Same Author</h3>
            <hr className="bookById__divider" />
          </div>
        )}

        {currentBookSameAuthor && currentBookSameAuthor.length > 0 && (
          <div className="bookById__ribbon">
            <BookRibbon
              onDeleteFromLibrary={() => {}}
              onAddToLibrary={addMediaToLibrary}
              onAddToCart={addMediaToCart}
              onBookSelect={selectMedia}
              books={currentBookSameAuthor}
              currency={userCurrency}
            />
          </div>
        )}
        <MediaAlert />
        <MediaSnackBar />
      </div>
    );
  }
};

export default BookById;
