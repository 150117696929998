import React, { useCallback } from 'react';
import { ILibraryItem, IVideo } from '../../models';
import { price, stringUtil } from '../../utility';
import './videoRibbon.scss';
import { VideoItem } from './VideoItem';

interface IVideoRibbonProps {
  video: IVideo[] | ILibraryItem[];
  currency: string;
  onVideoSelect: (video: IVideo | ILibraryItem) => void;
  onAddToCart: (video: IVideo) => void;
  onAddToLibrary: (video: IVideo) => void;
  onDeleteFromLibrary: (video: ILibraryItem) => void;
}

export const VideoRibbon: React.FC<IVideoRibbonProps> = ({
  video,
  currency,
  onVideoSelect,
  onAddToCart,
  onAddToLibrary,
  onDeleteFromLibrary,
}) => {
  const hasDiscount = useCallback(
    (video: IVideo | ILibraryItem) => {
      return (
        price.getDiscountPrice(
          currency,
          video.discountInGBP,
          video.discountInBDT,
          video.discountInUSD
        ) > 0 && video.isPaid
      );
    },
    [currency]
  );
  return (
    <div className="videoRibbon">
      {video.map((video: IVideo | ILibraryItem) => {
        return (
          <div key={video.id} className="videoRibbon__item">
            <VideoItem
              onDeleteFromLibrary={onDeleteFromLibrary}
              onAddToLibrary={onAddToLibrary}
              onAddToCart={onAddToCart}
              onVideoSelect={onVideoSelect}
              video={video}
            />
            <h5>{stringUtil.limitToMaxCharacter(video.title, 16)}</h5>
            {!hasDiscount(video) && (
              <p>
                {price.formatMediaPrice(
                  currency,
                  video.priceInGBP,
                  video.priceInBDT,
                  video.priceInUSD
                )}
              </p>
            )}

            {hasDiscount(video) && (
              <p className="videoRibbon__price">
                {price.formatMediaPriceAfterDiscount(
                  currency,
                  video.priceInGBP,
                  video.priceInBDT,
                  video.priceInUSD,
                  video.discountInGBP,
                  video.discountInBDT,
                  video.discountInUSD
                )}{' '}
                (
                <span className="videoRibbon__discount">
                  {price.formatMediaPrice(
                    currency,
                    video.priceInGBP,
                    video.priceInBDT,
                    video.priceInUSD
                  )}
                </span>
                )
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
};
