import { Paper } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { IFeatureItem } from '../../models';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import './featureItem.scss';
import { price, stringUtil } from '../../utility';
import { useStoreState } from '../../hooks';
import { Spinner } from 'react-bootstrap';

interface IFeatureItemProps {
  featureItem: IFeatureItem;
  currency: string;
  onFeatureSelect: (feature: IFeatureItem) => void;
  onAddToCart: (feature: IFeatureItem) => void;
  onAddToLibrary: (feature: IFeatureItem) => void;
}

export const FeatureItem: React.FC<IFeatureItemProps> = ({
  featureItem,
  currency,
  onFeatureSelect,
  onAddToCart,
  onAddToLibrary,
}) => {
  const hasDiscount = useCallback(
    (feature: IFeatureItem) => {
      return (
        price.getDiscountPrice(
          currency,
          feature.discountInGBP,
          feature.discountInBDT,
          feature.discountInUSD
        ) > 0 && feature.isPaid
      );
    },
    [currency]
  );
  const {
    cart: { isAddCartItemInProgress },
    library: { isAddLibraryItemInProgress },
  } = useStoreState();
  const coverArtSize = useMemo(() => {
    if (featureItem.media === 'Book') {
      return {
        width: 150,
        height: 225,
      };
    } else if (featureItem.media === 'Audio') {
      return {
        width: 150,
        height: 150,
      };
    } else {
      return {
        width: 200,
        height: 125,
      };
    }
  }, [featureItem]);
  return (
    <Paper
      onClick={() => onFeatureSelect(featureItem)}
      elevation={3}
      className="featureItem"
    >
      <div className="featureItem__coverArt">
        <img
          style={coverArtSize}
          alt={`${featureItem.title} cover`}
          src={featureItem.coverArt}
          className="featureItem__image"
        />
      </div>
      <div className="featureItem__info">
        <h5>{stringUtil.limitToMaxCharacter(featureItem.title, 15)}</h5>
        <p>{featureItem.contentType}</p>
        {!hasDiscount(featureItem) && (
          <p>
            {price.formatMediaPrice(
              currency,
              featureItem.priceInGBP,
              featureItem.priceInBDT,
              featureItem.priceInUSD
            )}
          </p>
        )}

        {hasDiscount(featureItem) && (
          <p className="featureItem__price">
            {price.formatMediaPriceAfterDiscount(
              currency,
              featureItem.priceInGBP,
              featureItem.priceInBDT,
              featureItem.priceInUSD,
              featureItem.discountInGBP,
              featureItem.discountInBDT,
              featureItem.discountInUSD
            )}{' '}
            (
            <span className="featureItem__discount">
              {price.formatMediaPrice(
                currency,
                featureItem.priceInGBP,
                featureItem.priceInBDT,
                featureItem.priceInUSD
              )}
            </span>
            )
          </p>
        )}
      </div>
      {featureItem.isPaid && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onAddToCart(featureItem);
          }}
          className="featureItem__addToCart"
        >
          {isAddCartItemInProgress && (
            <Spinner animation="border" variant="inherit" />
          )}
          {isAddCartItemInProgress && (
            <div style={{ marginLeft: 10 }}>Adding...</div>
          )}

          {!isAddCartItemInProgress && (
            <ShoppingCartIcon className="featureItem__icon" />
          )}

          {!isAddCartItemInProgress && <div>Add to cart</div>}
        </div>
      )}
      {!featureItem.isPaid && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onAddToLibrary(featureItem);
          }}
          className="featureItem__addToCart"
        >
          {isAddLibraryItemInProgress && (
            <Spinner animation="border" variant="inherit" />
          )}
          {isAddLibraryItemInProgress && (
            <div style={{ marginLeft: 10 }}>Adding...</div>
          )}
          {!isAddLibraryItemInProgress && (
            <LibraryBooksIcon className="featureItem__icon" />
          )}
          {!isAddLibraryItemInProgress && <div>Add to Library</div>}
        </div>
      )}
    </Paper>
  );
};
