import React, { useCallback, useMemo, useState } from 'react';
import { useMedia, useStoreState } from '../../hooks';
import { media } from '../../utility';
import { AudioRibbon, Banner, GenreList, Search } from '../common';
import { FeatureRibbon } from '../common/FeatureRibbon';
import MediaAlert from '../common/MediaAlert';
import MediaSnackBar from '../common/MediaSnackBar';
import './index.scss';

const Audios: React.FC = () => {
  const {
    media: { ribbonAudios, featuredAudios },
    auth: { userCurrency },
  } = useStoreState();
  const [selectedGenre, setSelectedGenre] = useState('All');
  const { selectMedia, searchMedia, addMediaToCart, addMediaToLibrary } =
    useMedia();

  const uniqueContentType = useMemo(() => {
    if (ribbonAudios) {
      return media.getUniqueMediaContentType(ribbonAudios);
    } else {
      return [];
    }
  }, [ribbonAudios]);

  const uniqueGenres = useMemo(() => {
    if (ribbonAudios) {
      return ['All', ...media.getUniqueMediaGenres(ribbonAudios)];
    } else {
      return [];
    }
  }, [ribbonAudios]);

  const displayAudios = useMemo(() => {
    if (ribbonAudios) {
      return media.getAudioByGenre(ribbonAudios, selectedGenre);
    } else {
      return [];
    }
  }, [ribbonAudios, selectedGenre]);

  const getAudiosByContentType = useCallback(
    (contentType: string) => {
      const filteredAudios = displayAudios.filter(
        (audio) => audio.contentType === contentType
      );

      return filteredAudios;
    },
    [displayAudios]
  );

  const handleGenreSelect = (genre: string) => {
    setSelectedGenre(genre);
  };

  return (
    <div className="audios">
      <div className="audios__banner">
        <Banner
          title="Cholishnu Audio"
          subtitle="We have curated collection of songs, interviews and podcasts for our users."
        />
      </div>
      <div className="audios__search">
        <Search
          onSearch={searchMedia}
          media="Audio"
          placeHolderText="🔎  Search Audio ..."
        />
      </div>
      {featuredAudios && featuredAudios.length > 0 && (
        <div className="audios__ribbonHeader">
          <h3>Featured</h3>
          <hr className="audios__divider" />
        </div>
      )}

      {featuredAudios && featuredAudios.length > 0 && (
        <div style={{ marginBottom: 30 }} className="audios__ribbon">
          <FeatureRibbon
            onAddToLibrary={addMediaToLibrary}
            onAddToCart={addMediaToCart}
            onFeatureSelect={selectMedia}
            features={featuredAudios}
            currency={userCurrency}
          />
        </div>
      )}
      <div className="audios__genre">
        <GenreList
          selectedGenre={selectedGenre}
          genres={uniqueGenres}
          onGenreSelect={handleGenreSelect}
        />
      </div>

      {ribbonAudios &&
        ribbonAudios.length > 0 &&
        displayAudios.length > 0 &&
        uniqueContentType.map((contentType) => {
          return (
            <div key={contentType}>
              {getAudiosByContentType(contentType).length > 0 && (
                <div className="audios__ribbonHeader">
                  <h3>{contentType}</h3>
                  <hr className="audios__divider" />
                </div>
              )}

              {ribbonAudios &&
                ribbonAudios.length > 0 &&
                displayAudios.length > 0 &&
                getAudiosByContentType(contentType).length > 0 && (
                  <div className="audios__ribbon">
                    <AudioRibbon
                      onDeleteFromLibrary={() => {}}
                      onAddToLibrary={addMediaToLibrary}
                      onAddToCart={addMediaToCart}
                      onAudioSelect={selectMedia}
                      audio={getAudiosByContentType(contentType)}
                      currency={userCurrency}
                    />
                  </div>
                )}
            </div>
          );
        })}
      <MediaAlert />
      <MediaSnackBar />
    </div>
  );
};

export default Audios;
