import {
  AlertTypeEnum,
  ICheckoutAddressInput,
  IShippingUserInput,
} from '../customTypes';
import { mobile as phoneUtil, envVariables } from '../utility';
import { loadStripe } from '@stripe/stripe-js';
import { useStoreActions } from './useStoreActions';
import { useStoreState } from './useStoreState';
import { useAlert } from './useAlert';

const stripePromise = loadStripe(envVariables.stripeKey!);

export const usePayment = () => {
  const {
    purchaseWithSSL,
    setSSLGateway,
    purchaseWithStripe,
    setStripeSession,
  } = useStoreActions();
  const {
    auth: { token, userCurrency, user, deliveryZones },
  } = useStoreState();
  const { openAlert } = useAlert();

  const payWithSSL = (
    totalAmount: number,
    userInfo: IShippingUserInput,
    userAddressInfo: ICheckoutAddressInput,
    mobile: string
  ) => {
    if (!user) {
      return;
    }

    if (!phoneUtil.validate(mobile)) {
      openAlert(
        AlertTypeEnum.ValidMobileNumberRequired,
        'You need to enter a valid mobile number'
      );
      return;
    }

    if (!deliveryZones) {
      return;
    }

    const deliveryZoneDetail = deliveryZones.find(
      (deliveryZone) =>
        deliveryZone.zoneCode === userAddressInfo.deliveryZoneCode
    );

    if (!deliveryZoneDetail) {
      return;
    }

    purchaseWithSSL(
      totalAmount,
      userCurrency,
      user.registeredCountry,
      userInfo,
      userAddressInfo,
      mobile,
      token,
      deliveryZoneDetail
    );
  };

  const payWithStripe = async (
    totalAmount: number,
    userInfo: IShippingUserInput,
    userAddressInfo: ICheckoutAddressInput,
    mobile: string
  ) => {
    if (!user) {
      return;
    }

    if (!phoneUtil.validate(mobile)) {
      openAlert(
        AlertTypeEnum.ValidMobileNumberRequired,
        'You need to enter a valid mobile number'
      );
      return;
    }

    if (!deliveryZones) {
      return;
    }

    const deliveryZoneDetail = deliveryZones.find(
      (deliveryZone) =>
        deliveryZone.zoneCode === userAddressInfo.deliveryZoneCode
    );

    if (!deliveryZoneDetail) {
      return;
    }

    purchaseWithStripe(
      totalAmount,
      userCurrency,
      user.registeredCountry,
      userInfo,
      userAddressInfo,
      mobile,
      token,
      deliveryZoneDetail
    );
  };

  const resetSSLGateway = () => {
    setSSLGateway(null);
  };

  const resetStripeSession = () => {
    setStripeSession(null);
  };

  const redirectToStripeCheckout = async (sessionId: string) => {
    const stripe = await stripePromise;

    if (!stripe) {
      return;
    }
    const result = await stripe.redirectToCheckout({
      sessionId,
    });

    if (result.error) {
      return;
    }
  };

  return {
    payWithSSL,
    resetSSLGateway,
    resetStripeSession,
    payWithStripe,
    redirectToStripeCheckout,
  };
};
