import React, { useCallback } from 'react';
import { IBook, ILibraryItem } from '../../models';
import { price, stringUtil } from '../../utility';
import { BookItem } from './BookItem';
import './bookRibbon.scss';

interface IBookRibbonProps {
  books: IBook[] | ILibraryItem[];
  currency: string;
  onBookSelect: (book: IBook | ILibraryItem) => void;
  onAddToCart: (book: IBook) => void;
  onAddToLibrary: (book: IBook) => void;
  onDeleteFromLibrary: (book: ILibraryItem) => void;
}

export const BookRibbon: React.FC<IBookRibbonProps> = ({
  books,
  currency,
  onBookSelect,
  onAddToCart,
  onAddToLibrary,
  onDeleteFromLibrary,
}) => {
  const hasDiscount = useCallback(
    (book: IBook | ILibraryItem) => {
      return (
        price.getDiscountPrice(
          currency,
          book.discountInGBP,
          book.discountInBDT,
          book.discountInUSD
        ) > 0 && book.isPaid
      );
    },
    [currency]
  );

  return (
    <div className="bookRibbon">
      {books.map((book: IBook | ILibraryItem) => {
        return (
          <div key={book.id} className="bookRibbon__item">
            <BookItem
              onAddToLibrary={onAddToLibrary}
              onDeleteFromLibrary={onDeleteFromLibrary}
              onAddToCart={onAddToCart}
              book={book}
              onBookSelect={onBookSelect}
            />
            <h5>{stringUtil.limitToMaxCharacter(book.title, 16)}</h5>
            <p>{book.contentType}</p>
            {!hasDiscount(book) && (
              <p>
                {price.formatMediaPrice(
                  currency,
                  book.priceInGBP,
                  book.priceInBDT,
                  book.priceInUSD
                )}
              </p>
            )}

            {hasDiscount(book) && (
              <p className="bookRibbon__price">
                {price.formatMediaPriceAfterDiscount(
                  currency,
                  book.priceInGBP,
                  book.priceInBDT,
                  book.priceInUSD,
                  book.discountInGBP,
                  book.discountInBDT,
                  book.discountInUSD
                )}{' '}
                (
                <span className="bookRibbon__discount">
                  {price.formatMediaPrice(
                    currency,
                    book.priceInGBP,
                    book.priceInBDT,
                    book.priceInUSD
                  )}
                </span>
                )
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
};
