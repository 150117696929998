import React, { useMemo } from 'react';
import { Badge, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cholishnuWhiteFullLogo from '../../assets/images/cholishnuWhiteFullLogo.png';
import { IUser } from '../../models';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import './appBar.scss';
import { useAlert, useRouter, useStoreState } from '../../hooks';
import { AlertTypeEnum } from '../../customTypes';

interface IAppBarProps {
  isAuthenticated: boolean;
  user: IUser | null;
}

const AppBar: React.FC<IAppBarProps> = ({ isAuthenticated, user }) => {
  const { navigateTo } = useRouter();
  const {
    cart: { cartItems },
  } = useStoreState();

  const numOfCartItems = useMemo(() => {
    return cartItems.length;
  }, [cartItems]);

  const isCartEmpty = useMemo(() => {
    return cartItems.length <= 0;
  }, [cartItems]);

  const { openAlert } = useAlert();

  const handleLogout = () => {
    openAlert(AlertTypeEnum.Logout, 'Are you sure you want to Logout?');
  };

  return (
    <>
      <Navbar className="appBar" expand="lg">
        <Navbar.Brand className="appBar__brand" as={Link} to="/">
          <img alt="cholishnu logo" src={cholishnuWhiteFullLogo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link className="appBar__link" as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link className="appBar__link" as={Link} to="/books">
              Books
            </Nav.Link>
            <Nav.Link className="appBar__link" as={Link} to="/audios">
              Audio
            </Nav.Link>
            <Nav.Link className="appBar__link" as={Link} to="/videos">
              Video
            </Nav.Link>
            <Nav.Link className="appBar__link" as={Link} to="/about">
              About
            </Nav.Link>
            <Nav.Link className="appBar__link" as={Link} to="/contact">
              Contact
            </Nav.Link>
          </Nav>
          {(!isAuthenticated || !user) && (
            <Nav.Link className="appBar__link active" as={Link} to="/login">
              Sign in
            </Nav.Link>
          )}
          {isAuthenticated && user && (
            <Nav className="appBar__user">
              <div
                className="appBar__iconContainer"
                onClick={() => navigateTo('/cart')}
              >
                {!isCartEmpty && (
                  <Badge className="appBar__badge" pill variant="danger">
                    {numOfCartItems}
                  </Badge>
                )}
                <ShoppingCartIcon className="appBar__icon" />
              </div>
              <NavDropdown
                title={`${user.firstName} ${user.lastName}`}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  className="appBar_userDropdownMenu"
                  as={Link}
                  to="/profile"
                >
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="appBar_userDropdownMenu"
                  as={Link}
                  to="/orders"
                >
                  My Orders
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="appBar_userDropdownMenu"
                  as={Link}
                  to="/library"
                >
                  My Library
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={handleLogout}
                  className="appBar_userDropdownMenu"
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default AppBar;
