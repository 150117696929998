import { IAudio, IBook, IFeatureItem, IVideo } from '../../models';
import { IMediaAction } from '../actions/interface';
import { ActionTypes } from '../types';

interface IMediaReducer {
  ribbonBooks: IBook[] | null;
  ribbonAudios: IAudio[] | null;
  ribbonVideos: IVideo[] | null;
  featured: IFeatureItem[] | null;
  featuredBooks: IFeatureItem[] | null;
  featuredAudios: IFeatureItem[] | null;
  featuredVideos: IFeatureItem[] | null;
  currentAudio: IAudio | null;
  currentBook: IBook | null;
  currentVideo: IVideo | null;
  currentBookSameAuthor: IBook[] | null;
  currentBookSamePublisher: IBook[] | null;
  isCurrentBookLoading: boolean;
  hasCurrentBookErrorOccured: boolean;
  isCurrentAudioLoading: boolean;
  hasCurrentAudioErrorOccured: boolean;
  isCurrentVideoLoading: boolean;
  hasCurrentVideoErrorOccured: boolean;
  hasRibbonBookLoadingErrorOccured: boolean;
  hasRibbonAudioLoadingErrorOccured: boolean;
  hasRibbonVideoLoadingErrorOccured: boolean;
  hasFeatureLoadingErrorOccured: boolean;
  isRibbonAudioLoading: boolean;
  isRibbonBooksLoading: boolean;
  isRibbonVideoLoading: boolean;
  isFeaturedItemLoading: boolean;
}

const initialState = {
  ribbonBooks: null,
  ribbonAudios: null,
  ribbonVideos: null,
  featured: null,
  featuredBooks: null,
  featuredAudios: null,
  featuredVideos: null,
  currentAudio: null,
  currentBook: null,
  currentVideo: null,
  currentBookSameAuthor: null,
  currentBookSamePublisher: null,
  isCurrentBookLoading: false,
  hasCurrentBookErrorOccured: false,
  isCurrentAudioLoading: false,
  hasCurrentAudioErrorOccured: false,
  isCurrentVideoLoading: false,
  hasCurrentVideoErrorOccured: false,
  hasRibbonBookLoadingErrorOccured: false,
  hasRibbonAudioLoadingErrorOccured: false,
  hasRibbonVideoLoadingErrorOccured: false,
  hasFeatureLoadingErrorOccured: false,
  isRibbonAudioLoading: false,
  isRibbonBooksLoading: false,
  isRibbonVideoLoading: false,
  isFeaturedItemLoading: false,
};

//eslint-disable-next-line
export default (
  state: IMediaReducer = initialState,
  action: IMediaAction
): IMediaReducer => {
  switch (action.type) {
    case ActionTypes.GET_RIBBON_BOOKS_IN_PROGRESS:
      return {
        ...state,
        isRibbonBooksLoading: true,
        hasRibbonBookLoadingErrorOccured: false,
      };
    case ActionTypes.GET_RIBBON_BOOKS_SUCCESS:
      return {
        ...state,
        isRibbonBooksLoading: false,
        hasRibbonBookLoadingErrorOccured: false,
        ribbonBooks: action.payload,
      };
    case ActionTypes.GET_RIBBON_BOOKS_FAILED:
      return {
        ...state,
        isRibbonBooksLoading: false,
        hasRibbonBookLoadingErrorOccured: true,
        ribbonBooks: [],
      };
    case ActionTypes.GET_RIBBON_AUDIO_IN_PROGRESS:
      return {
        ...state,
        isRibbonAudioLoading: true,
        hasRibbonAudioLoadingErrorOccured: false,
      };
    case ActionTypes.GET_RIBBON_AUDIO_SUCCESS:
      return {
        ...state,
        isRibbonAudioLoading: false,
        hasRibbonAudioLoadingErrorOccured: false,
        ribbonAudios: action.payload,
      };
    case ActionTypes.GET_RIBBON_AUDIO_FAILED:
      return {
        ...state,
        isRibbonAudioLoading: false,
        hasRibbonAudioLoadingErrorOccured: true,
        ribbonAudios: [],
      };
    case ActionTypes.GET_RIBBON_VIDEO_IN_PROGRESS:
      return {
        ...state,
        isRibbonVideoLoading: true,
        hasRibbonVideoLoadingErrorOccured: false,
      };
    case ActionTypes.GET_RIBBON_VIDEO_SUCCESS:
      return {
        ...state,
        isRibbonVideoLoading: false,
        hasRibbonVideoLoadingErrorOccured: false,
        ribbonVideos: action.payload,
      };
    case ActionTypes.GET_RIBBON_VIDEO_FAILED:
      return {
        ...state,
        isRibbonVideoLoading: false,
        hasRibbonVideoLoadingErrorOccured: true,
        ribbonVideos: [],
      };
    case ActionTypes.GET_FEATURES_IN_PROGRESS:
      return {
        ...state,
        isFeaturedItemLoading: true,
        hasFeatureLoadingErrorOccured: false,
      };
    case ActionTypes.GET_FEATURES_SUCCESS:
      return {
        ...state,
        isFeaturedItemLoading: false,
        hasFeatureLoadingErrorOccured: false,
        featured: action.payload.features,
        featuredAudios: action.payload.featuredAudio,
        featuredBooks: action.payload.featuredBooks,
        featuredVideos: action.payload.featuredVideo,
      };
    case ActionTypes.GET_FEATURES_FAILED:
      return {
        ...state,
        isFeaturedItemLoading: false,
        hasFeatureLoadingErrorOccured: true,
        featured: [],
        featuredAudios: [],
        featuredBooks: [],
        featuredVideos: [],
      };
    case ActionTypes.GET_BOOK_BY_ID_IN_PROGRESS:
      return {
        ...state,
        currentBook: null,
        isCurrentBookLoading: true,
        hasCurrentBookErrorOccured: false,
      };
    case ActionTypes.GET_BOOK_BY_ID_SUCCESS:
      return {
        ...state,
        currentBook: action.payload,
        isCurrentBookLoading: false,
        hasCurrentBookErrorOccured: false,
      };
    case ActionTypes.GET_BOOK_BY_ID_FAILED:
      return {
        ...state,
        currentBook: null,
        isCurrentBookLoading: false,
        hasCurrentBookErrorOccured: true,
      };
    case ActionTypes.GET_BOOKS_BY_SAME_AUTHOR:
      return {
        ...state,
        currentBookSameAuthor: action.payload,
      };
    case ActionTypes.GET_BOOKS_BY_SAME_PUBLISHER:
      return {
        ...state,
        currentBookSamePublisher: action.payload,
      };
    case ActionTypes.GET_BOOKS_BY_SAME_PUBLISHER_FAILED:
      return {
        ...state,
        currentBookSamePublisher: null,
      };
    case ActionTypes.GET_BOOKS_BY_SAME_AUTHOR_FAILED:
      return {
        ...state,
        currentBookSameAuthor: null,
      };
    case ActionTypes.GET_VIDEO_BY_ID_IN_PROGRESS:
      return {
        ...state,
        currentVideo: null,
        isCurrentVideoLoading: true,
        hasCurrentVideoErrorOccured: false,
      };
    case ActionTypes.GET_VIDEO_BY_ID_SUCCESS:
      return {
        ...state,
        currentVideo: action.payload,
        isCurrentVideoLoading: false,
        hasCurrentVideoErrorOccured: false,
      };
    case ActionTypes.GET_VIDEO_BY_ID_FAILED:
      return {
        ...state,
        currentVideo: null,
        isCurrentVideoLoading: false,
        hasCurrentVideoErrorOccured: true,
      };
    case ActionTypes.GET_AUDIO_BY_ID_IN_PROGRESS:
      return {
        ...state,
        currentAudio: null,
        isCurrentAudioLoading: true,
        hasCurrentAudioErrorOccured: false,
      };
    case ActionTypes.GET_AUDIO_BY_ID_SUCCESS:
      return {
        ...state,
        currentAudio: action.payload,
        isCurrentAudioLoading: false,
        hasCurrentAudioErrorOccured: false,
      };
    case ActionTypes.GET_AUDIO_BY_ID_FAILED:
      return {
        ...state,
        currentAudio: null,
        isCurrentAudioLoading: false,
        hasCurrentAudioErrorOccured: true,
      };
    default:
      return state;
  }
};
