import { useStoreActions } from './useStoreActions';
import { useRouter } from './useRouter';

export const useCheckout = () => {
  const { setCheckoutPrice } = useStoreActions();
  const { navigateTo } = useRouter();

  const checkout = (
    totalPrice: number | null,
    totalDiscount: number | null,
    totalVat: number | null
  ) => {
    setCheckoutPrice(totalPrice, totalDiscount, totalVat);

    if (totalPrice === null || totalDiscount === null || totalVat === null) {
      return;
    }
    navigateTo('/shipping');
  };

  return { checkout };
};
