import { PaginationEnum } from '../customTypes';
import { useSearch } from './useSearch';

export const usePagination = (pagination: PaginationEnum) => {
  const { search } = useSearch();

  const pageChange = (page: number, query?: string, media?: string) => {
    if (query && media && pagination === PaginationEnum.Search) {
      if (media === 'books') {
        search(query, page, 'Book');
      } else if (media === 'audios') {
        search(query, page, 'Audio');
      } else if (media === 'videos') {
        search(query, page, 'Video');
      }
    }
  };

  return { pageChange };
};
