import { useStoreActions } from './useStoreActions';
import { useStoreState } from './useStoreState';

export const useLoadMedia = () => {
  const {
    getRibbonBooks,
    getRibbonVideo,
    getRibbonAudio,
    getFeatureItems,
    getBookbyId,
    getBookBySamePublisher,
    getBookBySameAuthor,
    getVideobyId,
    getAudiobyId,
    getLibraryItems,
    getOrderHistory,
    getOrderById,
    getCartData,
  } = useStoreActions();

  const {
    auth: { token },
  } = useStoreState();

  const loadRibbonBooks = () => {
    getRibbonBooks();
  };

  const loadRibbonAudio = () => {
    getRibbonAudio();
  };

  const loadRibbonVideo = () => {
    getRibbonVideo();
  };

  const loadFeatureItems = () => {
    getFeatureItems();
  };

  const loadBookById = (id: number) => {
    getBookbyId(id);
  };

  const loadBookBySamePublisher = (id: number) => {
    getBookBySamePublisher(id);
  };

  const loadBookBySameAuthor = (id: number) => {
    getBookBySameAuthor(id);
  };

  const loadVideobyId = (id: number) => {
    getVideobyId(id);
  };

  const loadAudioById = (id: number) => {
    getAudiobyId(id);
  };

  const loadLibrary = () => {
    if (token) {
      getLibraryItems(token);
    }
  };

  const loadOrderHistory = () => {
    if (token) {
      getOrderHistory(token);
    }
  };

  const loadOrderById = (id: number) => {
    if (token) {
      getOrderById(id, token);
    }
  };

  const loadCartData = () => {
    if (token) {
      getCartData(token);
    }
  };

  return {
    loadFeatureItems,
    loadRibbonAudio,
    loadRibbonBooks,
    loadRibbonVideo,
    loadBookById,
    loadBookBySamePublisher,
    loadBookBySameAuthor,
    loadVideobyId,
    loadAudioById,
    loadLibrary,
    loadOrderHistory,
    loadOrderById,
    loadCartData,
  };
};
