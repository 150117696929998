import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLoadMedia, useRouter, useStoreState } from '../../hooks';
import { DataLoading } from '../common';
import './index.scss';
import OrderDetailItem from './OrderDetailItem';

const OrderById: React.FC = () => {
  const {
    order: { isOrderDetailLoading, currentOrder, hasOrderDetailErrorOccured },
  } = useStoreState();
  const { getParams, navigateTo } = useRouter();
  const { loadOrderById } = useLoadMedia();

  useEffect(() => {
    const id = getParams().id;
    loadOrderById(+id);
    //eslint-disable-next-line
  }, [getParams().id]);

  useEffect(() => {
    if (hasOrderDetailErrorOccured) {
      navigateTo('/orders');
    }
    //eslint-disable-next-line
  }, [hasOrderDetailErrorOccured]);

  return (
    <Container className="orderHistory">
      <Row>
        <Col>
          {isOrderDetailLoading && <DataLoading />}
          {currentOrder && currentOrder.length > 0 && !isOrderDetailLoading && (
            <div className="orderHistory__title">
              <h3>Order #{getParams().id}</h3>
              <hr />
            </div>
          )}
          {currentOrder &&
            currentOrder.length > 0 &&
            !isOrderDetailLoading &&
            currentOrder.map((order) => {
              return (
                <div key={order.itemId}>
                  <OrderDetailItem order={order} />
                </div>
              );
            })}
        </Col>
      </Row>
    </Container>
  );
};

export default OrderById;
