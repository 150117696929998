import React, { useCallback } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { Paper } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import './videoDetail.scss';
import { IVideo } from '../../models';
import { price } from '../../utility';
import { useStoreState } from '../../hooks';

interface IVideoDetail {
  video: IVideo;
  userCurrency: string;
  onAddToCart: (video: IVideo) => void;
  onAddToLibrary: (video: IVideo) => void;
}

const VideoDetail: React.FC<IVideoDetail> = ({
  video,
  userCurrency,
  onAddToCart,
  onAddToLibrary,
}) => {
  const {
    cart: { isAddCartItemInProgress },
    library: { isAddLibraryItemInProgress },
  } = useStoreState();

  const hasDiscount = useCallback(
    (video: IVideo) => {
      return (
        price.getDiscountPrice(
          userCurrency,
          video.discountInGBP,
          video.discountInBDT,
          video.discountInUSD
        ) > 0 && video.isPaid
      );
    },
    [userCurrency]
  );
  return (
    <Container className="videoDetail">
      <Row>
        <Col xl={6}>
          <Paper elevation={3} className="videoDetail__bookCard">
            <img
              alt={`${video.title} cover`}
              src={video.coverArt}
              className="videoDetail__image"
            />
          </Paper>
        </Col>
        <Col xl={6}>
          <Row>
            <Col className="videoDetail__basicInfo">
              <h2>{video.title}</h2>
              <h4>{video.contentType}</h4>
              {!hasDiscount(video) && (
                <h6>
                  {price.formatMediaPrice(
                    userCurrency,
                    video.priceInGBP,
                    video.priceInBDT,
                    video.priceInUSD
                  )}
                </h6>
              )}

              {hasDiscount(video) && (
                <h6 className="videoDetail__price">
                  {price.formatMediaPriceAfterDiscount(
                    userCurrency,
                    video.priceInGBP,
                    video.priceInBDT,
                    video.priceInUSD,
                    video.discountInGBP,
                    video.discountInBDT,
                    video.discountInUSD
                  )}{' '}
                  (
                  <span className="videoDetail__discount">
                    {price.formatMediaPrice(
                      userCurrency,
                      video.priceInGBP,
                      video.priceInBDT,
                      video.priceInUSD
                    )}
                  </span>
                  )
                </h6>
              )}

              {(isAddCartItemInProgress || isAddLibraryItemInProgress) && (
                <Spinner animation="border" variant="primary" />
              )}

              {(video.isPaid || (!video.isPaid && !video.isEContent)) &&
                !isAddCartItemInProgress && (
                  <Button
                    onClick={() => onAddToCart(video)}
                    className="videoDetail__button"
                  >
                    <ShoppingCartIcon className="bookItem__icon" /> Add to cart
                  </Button>
                )}

              {!video.isPaid &&
                video.isEContent &&
                !isAddLibraryItemInProgress && (
                  <Button
                    onClick={() => onAddToLibrary(video)}
                    className="videoDetail__button"
                  >
                    <LibraryBooksIcon className="bookItem__icon" /> Add to
                    Library
                  </Button>
                )}
            </Col>
          </Row>
          <hr className="bookItem__divider" />
          <Row>
            <Col className="videoDetail__authorInfo">
              <h6>Featuring:</h6>
              {video.artist.map((item) => {
                return <div key={item.id}>{item.name}</div>;
              })}
            </Col>
            <Col className="videoDetail__authorInfo">
              <h6>Genres:</h6>
              {video.genre.map((item) => {
                return <div key={item.id}>{item.name}</div>;
              })}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="videoDetail__description">
          <h4>Description</h4>
          <hr />
          <p>{video.description}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default VideoDetail;
