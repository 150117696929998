import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { IEditProfileFormInput } from '../../customTypes';
import { useStoreState } from '../../hooks';
import { date, mobile as phoneUtil } from '../../utility';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Form } from 'react-bootstrap';

interface IEditProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (userInfo: IEditProfileFormInput, mobile: string) => void;
}

const titles = [
  {
    value: 'Mr',
    label: 'Mr',
  },
  {
    value: 'Mrs',
    label: 'Mrs',
  },
  {
    value: 'Dr',
    label: 'Dr',
  },
  {
    value: 'Ms',
    label: 'Ms',
  },
  {
    value: 'Jr',
    label: 'Jr',
  },
  {
    value: 'Hon',
    label: 'Hon',
  },
  {
    value: 'Hon',
    label: 'Hon',
  },
  {
    value: 'Prof',
    label: 'Prof',
  },
  {
    value: 'Sr',
    label: 'Sr',
  },
];

const EditProfileModal: React.FC<IEditProfileModalProps> = ({
  isOpen,
  onClose,
  onUpdate,
}) => {
  const {
    auth: { user, isUpdateProfileInProgress },
  } = useStoreState();

  const [formInput, setFormInput] = useState<IEditProfileFormInput>({
    title: user && user.title ? user.title : '',
    firstName: user && user.firstName ? user.firstName : '',
    lastName: user && user.lastName ? user.lastName : '',
    dob: user && user.dob ? date.shortFormat(user.dob) : '',
    address1: user && user.address1 ? user.address1 : '',
    address2: user && user.address2 ? user.address2 : '',
    city: user && user.city ? user.city : '',
    state: user && user.state ? user.state : '',
    postCode: user && user.postCode ? user.postCode : '',
  });
  const [mobile, setMobile] = useState('');
  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFormInput({
      ...formInput,
      [event.target.name]: event.target.value,
    });
  };

  const defaultMobileCode = useMemo(() => {
    if (user && user.registeredCountry) {
      return user.registeredCountry;
    } else {
      return 'BD';
    }
  }, [user]);

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onUpdate(formInput, mobile);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Profile</DialogTitle>
      <Form onSubmit={handleOnSubmit}>
        <DialogContent>
          <TextField
            fullWidth
            id="standard-select-currency"
            select
            label="Title"
            value={formInput.title}
            onChange={handleInputChange}
          >
            {titles.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="First Name"
            name="firstName"
            value={formInput.firstName}
            onChange={handleInputChange}
            type="text"
            required
            fullWidth
          />
          <TextField
            margin="dense"
            label="Last Name"
            name="lastName"
            value={formInput.lastName}
            onChange={handleInputChange}
            type="text"
            required
            fullWidth
          />
          <TextField
            margin="dense"
            label="Address 1"
            name="address1"
            value={formInput.address1}
            onChange={handleInputChange}
            type="text"
            required
            fullWidth
          />
          <TextField
            margin="dense"
            label="Address 2"
            name="address2"
            value={formInput.address2}
            onChange={handleInputChange}
            type="text"
            fullWidth
          />
          <TextField
            margin="dense"
            label="City"
            name="city"
            value={formInput.city}
            onChange={handleInputChange}
            type="text"
            required
            fullWidth
          />
          <TextField
            margin="dense"
            label="State"
            name="state"
            value={formInput.state}
            onChange={handleInputChange}
            type="text"
            required
            fullWidth
          />
          <TextField
            margin="dense"
            label="PostCode"
            name="postCode"
            value={formInput.postCode}
            onChange={handleInputChange}
            type="text"
            required
            fullWidth
          />

          <Form.Group controlId="formBasicPassword">
            <Form.Label className="text-muted">Mobile</Form.Label>
            <PhoneInput
              required
              className="registerForm__phoneInput"
              international
              placeholder="Enter phone number"
              defaultCountry={phoneUtil.covertAlpha3ToAlpha2(defaultMobileCode)}
              value={mobile}
              onChange={setMobile}
            />
          </Form.Group>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose} color="secondary">
            Cancel
          </Button>
          {isUpdateProfileInProgress ? (
            <CircularProgress />
          ) : (
            <Button type="submit" variant="contained" color="primary">
              Update
            </Button>
          )}
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default EditProfileModal;
