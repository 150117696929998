import { AlertTypeEnum } from '../customTypes';
import {
  IAudio,
  IBook,
  IFeatureItem,
  ILibraryItem,
  ISearchItem,
  IVideo,
} from '../models';
import { price } from '../utility';
import { useAlert } from './useAlert';
import { useRouter } from './useRouter';
import { useSearch } from './useSearch';
import { useStoreActions } from './useStoreActions';
import { useStoreState } from './useStoreState';

export const useMedia = () => {
  const { navigateTo } = useRouter();
  const { search } = useSearch();
  const { addItemToCart, addItemToLibrary } = useStoreActions();
  const {
    auth: { userVat, token, userCurrency, user, isAuthenticated },
    cart: { cartItems },
    library: { libraryAudios, libraryBooks, libraryVideos },
  } = useStoreState();
  const { openAlert } = useAlert();

  const selectMedia = (
    media: IBook | IAudio | IVideo | ISearchItem | IFeatureItem | ILibraryItem
  ) => {
    if (media.media === 'Book') {
      navigateTo(`/books/${media.id}`);
    } else if (media.media === 'Video') {
      navigateTo(`/videos/${media.id}`);
    } else if (media.media === 'Audio') {
      navigateTo(`/audios/${media.id}`);
    }
  };

  const searchMedia = (media: string, query: string) => {
    if (media === 'Book') {
      search(query, 1, 'Book');
      navigateTo('/search/books');
    } else if (media === 'Video') {
      search(query, 1, 'Video');
      navigateTo('/search/videos');
    } else if (media === 'Audio') {
      search(query, 1, 'Audio');
      navigateTo('/search/audios');
    }
  };

  const addMediaToCart = (media: IBook | IAudio | IVideo | IFeatureItem) => {
    if (!isAuthenticated) {
      openAlert(
        AlertTypeEnum.SignInRequired,
        'You need to Sign in to buy this item'
      );

      return;
    }

    if (media.isEContent) {
      const mediaInCart = cartItems.find((item) => item.itemId === media.id);

      if (mediaInCart) {
        openAlert(
          AlertTypeEnum.ItemAlreadyInCart,
          'You have already added this item to cart. You cannot add E-Content more than once.'
        );
        return;
      }

      if (media.media === 'Book') {
        const mediaInLibrary = libraryBooks.find(
          (item) => item.id === media.id
        );

        if (mediaInLibrary) {
          openAlert(
            AlertTypeEnum.ItemAlreadInLibrary,
            'This item is already in your library.'
          );
          return;
        }
      } else if (media.media === 'Audio') {
        const mediaInLibrary = libraryAudios.find(
          (item) => item.id === media.id
        );

        if (mediaInLibrary) {
          openAlert(
            AlertTypeEnum.ItemAlreadInLibrary,
            'This item is already in your library.'
          );
          return;
        }
      } else {
        const mediaInLibrary = libraryVideos.find(
          (item) => item.id === media.id
        );

        if (mediaInLibrary) {
          openAlert(
            AlertTypeEnum.ItemAlreadInLibrary,
            'This item is already in your library.'
          );
          return;
        }
      }
    }

    addItemToCart(
      token,
      media,
      price.getMediaPrice(
        userCurrency,
        media.priceInGBP,
        media.priceInBDT,
        media.priceInUSD
      ),
      price.getDiscountPrice(
        userCurrency,
        media.discountInGBP,
        media.discountInBDT,
        media.discountInUSD
      ),
      userVat
    );
  };

  const addMediaToLibrary = (media: IBook | IAudio | IVideo | IFeatureItem) => {
    if (!isAuthenticated) {
      openAlert(
        AlertTypeEnum.SignInRequired,
        'You need to Sign in to add this item to library'
      );

      return;
    }

    if (media.isPaid) {
      return;
    }
    const mediaInCart = cartItems.find((item) => item.itemId === media.id);

    if (mediaInCart) {
      openAlert(
        AlertTypeEnum.ItemAlreadyInCart,
        'You have already added this item to cart. You cannot add E-Content more than once.'
      );
      return;
    }

    if (media.media === 'Book') {
      const mediaInLibrary = libraryBooks.find((item) => item.id === media.id);

      if (mediaInLibrary) {
        openAlert(
          AlertTypeEnum.ItemAlreadInLibrary,
          'This item is already in your library.'
        );
        return;
      }
    } else if (media.media === 'Audio') {
      const mediaInLibrary = libraryAudios.find((item) => item.id === media.id);

      if (mediaInLibrary) {
        openAlert(
          AlertTypeEnum.ItemAlreadInLibrary,
          'This item is already in your library.'
        );
        return;
      }
    } else {
      const mediaInLibrary = libraryVideos.find((item) => item.id === media.id);

      if (mediaInLibrary) {
        openAlert(
          AlertTypeEnum.ItemAlreadInLibrary,
          'This item is already in your library.'
        );
        return;
      }
    }

    if (!user) {
      return;
    }

    if (!token) {
      return;
    }

    addItemToLibrary(token, user.id, media.id, media.media);
  };

  return { selectMedia, searchMedia, addMediaToCart, addMediaToLibrary };
};
