import { ICartItem } from '../../models';
import { ICartAction } from '../actions/interface';
import { ActionTypes } from '../types';

interface ICartReducer {
  cartItems: ICartItem[];
  isCartItemLoading: boolean;
  isAddCartItemInProgress: boolean;
  hasAddCartItemFailed: boolean;
  isAddCartItemSuccessful: boolean;
}

const initialState = {
  cartItems: [],
  isCartItemLoading: false,
  isAddCartItemInProgress: false,
  isAddCartItemSuccessful: false,
  hasAddCartItemFailed: false,
};

//eslint-disable-next-line
export default (
  state: ICartReducer = initialState,
  action: ICartAction
): ICartReducer => {
  switch (action.type) {
    case ActionTypes.GET_CART_DATA_IN_PROGRESS:
      return {
        ...state,
        cartItems: [],
        isCartItemLoading: true,
      };
    case ActionTypes.GET_CART_DATA_SUCCESS:
      return {
        ...state,
        cartItems: action.payload,
        isCartItemLoading: false,
      };
    case ActionTypes.GET_CART_DATA_FAILED:
      return {
        ...state,
        cartItems: [],
        isCartItemLoading: false,
      };
    case ActionTypes.INCREASE_CART_ITEM_QUANTITY:
      return {
        ...state,
        cartItems: state.cartItems.map((item) => {
          if (item.basketId === action.payload) {
            item.totalQuantity = item.totalQuantity + 1;
            item.totalPrice = item.totalPrice + item.pricePerQty;
            item.totalToken = item.totalToken + item.tokenPerQty;
            item.discount = item.discount + item.discountPerQty;
          }

          return item;
        }),
      };
    case ActionTypes.DECREASE_CART_ITEM_QUANTITY:
      return {
        ...state,
        cartItems: state.cartItems.map((item) => {
          if (item.basketId === action.payload) {
            item.totalQuantity = item.totalQuantity - 1;
            item.totalPrice = item.totalPrice - item.pricePerQty;
            item.totalToken = item.totalToken - item.tokenPerQty;
            item.discount = item.discount - item.discountPerQty;
          }

          return item;
        }),
      };
    case ActionTypes.DELETE_CART_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item) => item.basketId !== action.payload
        ),
      };
    case ActionTypes.REMOVE_ALL_CART_ITEMS:
      return {
        ...state,
        cartItems: [],
      };
    case ActionTypes.ADD_ITEM_TO_CART_IN_PROGRESS:
      return {
        ...state,
        isAddCartItemInProgress: true,
        hasAddCartItemFailed: false,
        isAddCartItemSuccessful: false,
      };
    case ActionTypes.ADD_ITEM_TO_CART_SUCCESS:
      return {
        ...state,
        isAddCartItemInProgress: false,
        hasAddCartItemFailed: false,
        isAddCartItemSuccessful: true,
      };
    case ActionTypes.ADD_ITEM_TO_CART_FAILED:
      return {
        ...state,
        isAddCartItemInProgress: false,
        hasAddCartItemFailed: true,
        isAddCartItemSuccessful: false,
      };
    case ActionTypes.RESET_ADD_TO_CART_SUCCESS:
      return {
        ...state,
        isAddCartItemInProgress: false,
        hasAddCartItemFailed: false,
        isAddCartItemSuccessful: false,
      };
    default:
      return state;
  }
};
