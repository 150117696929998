import { Paper } from '@material-ui/core';
import React from 'react';
import { ILibraryItem, IVideo } from '../../models';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import DeleteIcon from '@material-ui/icons/Delete';
import './videoItem.scss';
import { useRouteMatch } from 'react-router-dom';
import { useStoreState } from '../../hooks';
import { Spinner } from 'react-bootstrap';

interface IVideoItemProps {
  video: IVideo | ILibraryItem;
  onVideoSelect: (video: IVideo | ILibraryItem) => void;
  onAddToCart: (video: IVideo) => void;
  onAddToLibrary: (video: IVideo) => void;
  onDeleteFromLibrary: (video: ILibraryItem) => void;
}

export const VideoItem: React.FC<IVideoItemProps> = ({
  video,
  onVideoSelect,
  onAddToCart,
  onAddToLibrary,
  onDeleteFromLibrary,
}) => {
  const isLibraryRoute = useRouteMatch('/library');
  const {
    cart: { isAddCartItemInProgress },
    library: { isAddLibraryItemInProgress, isDeleteLibraryItemInProgress },
  } = useStoreState();
  return (
    <Paper
      onClick={() => onVideoSelect(video)}
      elevation={3}
      className="videoItem"
    >
      <img
        alt={`${video.title} cover`}
        src={video.coverArt}
        className="videoItem__image"
      />
      {video.isPaid && !isLibraryRoute && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onAddToCart(video as IVideo);
          }}
          className="videoItem__addToCart"
        >
          {isAddCartItemInProgress && (
            <Spinner animation="border" variant="inherit" />
          )}
          {isAddCartItemInProgress && (
            <div style={{ marginLeft: 10 }}>Adding...</div>
          )}

          {!isAddCartItemInProgress && (
            <ShoppingCartIcon className="videoItem__icon" />
          )}

          {!isAddCartItemInProgress && <div>Add to cart</div>}
        </div>
      )}
      {!video.isPaid && !isLibraryRoute && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onAddToLibrary(video as IVideo);
          }}
          className="videoItem__addToCart"
        >
          {isAddLibraryItemInProgress && (
            <Spinner animation="border" variant="inherit" />
          )}
          {isAddLibraryItemInProgress && (
            <div style={{ marginLeft: 10 }}>Adding...</div>
          )}
          {!isAddLibraryItemInProgress && (
            <LibraryBooksIcon className="videoItem__icon" />
          )}
          {!isAddLibraryItemInProgress && <div>Add to Library</div>}
        </div>
      )}

      {isLibraryRoute && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onDeleteFromLibrary(video as ILibraryItem);
          }}
          className="videoItem__addToCart delete"
        >
          {isDeleteLibraryItemInProgress && (
            <Spinner animation="border" variant="inherit" />
          )}
          {isDeleteLibraryItemInProgress && (
            <div style={{ marginLeft: 10 }}>Deleting...</div>
          )}
          {!isDeleteLibraryItemInProgress && (
            <DeleteIcon className="videoItem__icon" />
          )}
          {!isDeleteLibraryItemInProgress && <div>Delete</div>}
        </div>
      )}
    </Paper>
  );
};
