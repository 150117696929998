import { useStoreActions } from './useStoreActions';

export const useSearch = () => {
  const { searchForMedia, clearSearch } = useStoreActions();

  const search = (query: string, page: number, media: string) => {
    searchForMedia(media, query, page);
  };

  const clear = () => {
    clearSearch();
  };

  return { search, clear };
};
