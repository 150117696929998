import { AlertTypeEnum } from '../customTypes';
import { useStoreActions } from './useStoreActions';

export const useAlert = () => {
  const { setAlert, resetAlert } = useStoreActions();

  const openAlert = (type: AlertTypeEnum, message: string) => {
    setAlert(type, message);
  };

  const closeAlert = () => {
    resetAlert();
  };

  return { openAlert, closeAlert };
};
